import { useQuery } from '@apollo/client';
import { GoogleMap, HeatmapLayer, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { gql } from 'apollo-boost';
import React, { useEffect, useRef, useState } from 'react';
import { FaLayerGroup } from "react-icons/fa";
import RecordDialog from "../components/RecordDialog";
import ViewRecord from "../components/ViewRecord";

const FIND_ALL_INCIDENTS_QUERY = gql`
  query findAllValidatedByFilterWithPagination1($startDate: DateTime, $endDate: DateTime) {
    incident_findAllByFilterWithPagination(filters: {
      fromDate: $startDate,
      toDate: $endDate,
      isVerified: true,
    }, options: {
      paginationOption: {
        page: 1,
        resultsPerPage: 3000
      }
    }){
      paginationDetails {
        page
        resultsPerPage
        totalCount
        hasPreviousPage
        hasNextPage
        previousPage
        nextPage
        numberOfPages
      }
      entities {
          id
          isVerified
          incidentDetails {
            id
            location {
              latitude
              longitude
              address
            }
            fromDateTime
            toDateTime
          }
          parties {
          id
          minors {
          id
          firstName
          middleName
          lastName
          gender
          gradeLevel
          involvement
          address
          licenseNumber
          age
          driverError
          alchoholSuspicion
          drugsSuspicion
          seatbeltState
          hospital
        }
        }
      }
    }
  }
`;

const FIND_ALL_INCIDENTS_QUERY_1 = gql`
  query findAllValidatedByFilterWithPagination1($startDate: DateTime, $endDate: DateTime) {
    incident_findAllByFilterWithPagination(filters: {fromDate: $startDate, toDate: $endDate, isVerified: true, hasMinors: true
    }, options: {paginationOption: {page: 1, resultsPerPage: 3000}}){
      paginationDetails {
        page
        resultsPerPage
        totalCount
        hasPreviousPage
        hasNextPage
        previousPage
        nextPage
        numberOfPages
      }
      entities {
          id
          isVerified
          incidentDetails {
            id
            location {
              latitude
              longitude
              address
            }
            fromDateTime
            toDateTime
          }
          parties {
          id
          minors {
          id
          firstName
          middleName
          lastName
          gender
          gradeLevel
          involvement
          address
          licenseNumber
          age
          driverError
          alchoholSuspicion
          drugsSuspicion
          seatbeltState
          hospital
        }
        }
        notes
      }
    }
  }
`;

const MapComponent = () => {
  var startDate = sessionStorage.getItem("start-date-filter");
  var endDate = sessionStorage.getItem("end-date-filter");

  // State to track which checkboxes are checked
  const [checkedBoxes, setCheckedBoxes] = useState([false, false, false, false]);

  // State to store data from the queries
  const [showMarkers, setShowMarkers] = useState(false);
  const [showHeatmap, setShowHeatmap] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowVisible, setInfoWindowVisible] = useState(false);
  const [heatmapData, setHeatmapData] = useState([]);
  const [hoveringMouse, sethoveringMouse] = useState(true);
  const [hoveringMouse2, sethoveringMouse2] = useState(false);
  const divRef = useRef(null);
  const [queryData, setQueryData] = useState([]);


  const handleCheckboxChange = (index) => {
    // Reset all checkboxes and set the clicked one to true
    const updatedCheckedBoxes = [false, false, false, false];
    updatedCheckedBoxes[index] = !checkedBoxes[index];
    setCheckedBoxes(updatedCheckedBoxes);

    // Determine if markers or heatmap should be shown
    const shouldShowMarkers = updatedCheckedBoxes[0] || updatedCheckedBoxes[2];
    const shouldShowHeatmap = updatedCheckedBoxes[1] || updatedCheckedBoxes[3];

    setShowMarkers(shouldShowMarkers);
    setShowHeatmap(shouldShowHeatmap);

    // Clear heatmap data whenever a checkbox is clicked
    setHeatmapData([]);

};

  // useQuery hook for the first query
  const { data: data1, loading: loading1, error: error1 } = useQuery(FIND_ALL_INCIDENTS_QUERY, {
    skip: !checkedBoxes[0] && !checkedBoxes[1], // Trigger QUERY_1 only if checkbox 1 or 2 is selected
    variables: { startDate, endDate }
  });

  // useQuery hook for the second query
  const { data: data2, loading: loading2, error: error2 } = useQuery(FIND_ALL_INCIDENTS_QUERY_1, {
    skip: !checkedBoxes[2] && !checkedBoxes[3], // Trigger QUERY_2 only if checkbox 3 or 4 is selected
    variables: { startDate, endDate }
  });

 

    // Effect to update the heatmap data based on the query results
    useEffect(() => {
      let incidents = [];
  
      if (data1 && (checkedBoxes[0] || checkedBoxes[1])) {
        incidents = data1.incident_findAllByFilterWithPagination.entities;
      } else if (data2 && (checkedBoxes[2] || checkedBoxes[3])) {
        incidents = data2.incident_findAllByFilterWithPagination.entities;
      }

    // Update the incidents state
    setQueryData(incidents);
  
      const heatmapLocations = incidents.map(incident => ({
        location: new window.google.maps.LatLng(
          incident.incidentDetails.location.latitude,
          incident.incidentDetails.location.longitude
        ),
        weight: 1
      }));
  
      setHeatmapData(heatmapLocations);

      


    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        sethoveringMouse(true);
        sethoveringMouse2(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    }, [data1, data2, checkedBoxes]);
  

  const gradient = [
    "rgba(0, 255, 255, 0)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 191, 255, 1)",
    "rgba(0, 127, 255, 1)",
    "rgba(0, 63, 255, 1)",
    "rgba(0, 0, 255, 1)",
    "rgba(0, 0, 223, 1)",
    "rgba(0, 0, 191, 1)",
    "rgba(0, 0, 159, 1)",
    "rgba(0, 0, 127, 1)",
    "rgba(63, 0, 91, 1)",
    "rgba(127, 0, 63, 1)",
    "rgba(191, 0, 31, 1)",
    "rgba(255, 0, 0, 1)",
  ];
  const [dialogIsOpen1, setDialogIsOpen1] = React.useState(false);
  const openDialog1 = () => setDialogIsOpen1(true);
  const [address, setAddress] = useState('');

  const closeDialog1 = () => {
    setDialogIsOpen1(false);
    sessionStorage.setItem("record-button", "not-clicked");
    sessionStorage.removeItem("lat");
    sessionStorage.removeItem("lng");
    sessionStorage.removeItem("loc");
  };

  const handleClick = (e) => {
    var recordbutton = sessionStorage.getItem("record-button");

    if (recordbutton === 'clicked') {
      sessionStorage.setItem("lat", e.latLng.lat());
      sessionStorage.setItem("lng", e.latLng.lng());

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat: e.latLng.lat(), lng: e.latLng.lng() } }, (results, status) => {
        if (status === 'OK' && results[0]) {
          sessionStorage.setItem("loc", results[0].formatted_address);
          setAddress(results[0].formatted_address);
        } else {
          setAddress('');
        }
      });

      openDialog1();
    }
  };

  const OpenLayerMenu = () => {
    sethoveringMouse(false);
    sethoveringMouse2(true);
  };
  
  const onSelectMarker = (incident) => {
    if (selectedMarker === incident) {
      setInfoWindowVisible(false);
      setSelectedMarker(null);
    } else {
      setSelectedMarker(incident);
      setInfoWindowVisible(true);
    }
  };

  const [dialogIsOpen6, setDialogIsOpen6] = React.useState(false);
  const openDialog6 = () => setDialogIsOpen6(true);

  const closeDialog6 = () => {
    setDialogIsOpen6(false);

    sessionStorage.removeItem("incident-id");
 
  };

  const mapStyles = [
    {
      featureType: "poi",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    }
  ];

  return (
    <div style={{ position: 'relative', height: '95vh', width: '100%' }}>
      <div style={{ position: 'relative', top: '10px', right: '10px', zIndex: 1000, backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '5px', fontSize: '13px', float: 'right', border: '2px solid grey' }}>
      <button style={{width: '30px', height: '20px', fontSize: '20px', border: 'none', backgroundColor: 'white'}}
          onClick={OpenLayerMenu} hidden={hoveringMouse2 ? true : false}>
          <FaLayerGroup style={{border: 'none'}} />
        </button>
        <div ref={divRef} style={{top: '20px', right: '10px', backgroundColor: 'white', paddingRight: '5px', paddingTop: '5px', paddingBottom: '5px', borderRadius: '5px', fontSize: '12px'}} hidden={hoveringMouse ? true : false}>
          <label>
            <input
              type="checkbox"
              checked={checkedBoxes[0]}
              onChange={() => handleCheckboxChange(0)}
            />
            &nbsp;Incidents (all)
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              checked={checkedBoxes[1]}
              onChange={() => handleCheckboxChange(1)}
              
            />
            &nbsp;Heatmap (all)
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              checked={checkedBoxes[2]}
              onChange={() => handleCheckboxChange(2)}
            />
            &nbsp;Incidents (children only)
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              checked={checkedBoxes[3]}
              onChange={() => handleCheckboxChange(3)}
            />
            &nbsp;Heatmap (children only)
          </label>
        </div>
      </div>

      <LoadScript googleMapsApiKey="AIzaSyDmbDwONw8uAMhun-c5bnkcKS0KA6_Gmas" libraries={["visualization"]}>
        <GoogleMap
          mapContainerStyle={{ position: 'absolute', width: '100%', height: '95vh' }}
          center={{ lat: 14.5995, lng: 120.9842 }}
          zoom={11}
          options={{ 
            disableDefaultUI: true,
            mapTypeControl: false,
            zoomControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            styles: mapStyles,
          }}
          onClick={handleClick}
        >
          {showMarkers && queryData.map(incident => (
            <Marker
              key={incident.id}
              position={{
                lat: incident.incidentDetails.location.latitude,
                lng: incident.incidentDetails.location.longitude,
              }}
              title={incident.incidentDetails.location.address}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/blue.png"
              }}
              onClick={() => onSelectMarker(incident)}
              zIndex={selectedMarker === incident ? 1000 : 1}

            
            />
          ))}

          {showHeatmap && (
            <HeatmapLayer
              data={heatmapData}
              options={{
                radius: 20,
                opacity: 1.1,
                gradient: gradient
              }}
            />
          )}

          {infoWindowVisible && selectedMarker && (
              <InfoWindow
              position={{
                lat: selectedMarker.incidentDetails.location.latitude,
                lng: selectedMarker.incidentDetails.location.longitude,
              }}
              onCloseClick={() => {
                setInfoWindowVisible(false);
                setSelectedMarker(null);
              }}
              visible={infoWindowVisible}
            >
              <div>
                <h2>{selectedMarker.incidentDetails.fromDateTime}</h2><br></br>
                <p>{selectedMarker.incidentDetails.location.address}</p>
                <br></br>
                <button
                  onClick={() => {
                    sessionStorage.setItem("incident-id", selectedMarker.id);
                  openDialog6();
                  }}

                >View</button>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>

      <RecordDialog open={dialogIsOpen1} onClose={closeDialog1} />
      <ViewRecord open={dialogIsOpen6} onClose={closeDialog6} />
    </div>
  );
};

export default MapComponent;
